<template>
    <div class="hotelDetail">        
        <lazy-component class="hotelDetailDiv1">
            <img class="hotelDetailTopImg"
                :src="hotelImageMain != '' ? hotelImageMain : ''">
            <div class="resortTitleDivParent">
                <div class="resortTitleDiv">
                    {{selectedHotel.hotelname}}
                </div>
            </div>
        </lazy-component>
        <lazy-component class="hotelDetailDiv2">
            <div class="hotelDetailSubDiv">
                <div class="homeSearchMain">
                
                    <div class="hotelDetailSearchMainSub">

                        <div class="homeSearchMainSub4">
                            <div class="dateSelectHotelDiv" >
                                <div style="position:relative;" >
                                    <a-select v-model="selHotelInfo" v-show="hotelList.length > 0"
                                        ref="pickerResorts"
                                        :open="pickerResortsOpen"
                                        :filter-option="filterOption" @change="handleHotelNameChange" 
                                        @blur="onBlurHotel" @dropdownVisibleChange="dropdownHotelChange" style="width:100%" mode="tags" placeholder="View All or Resort Name">
                                        <a-select-option v-for="_item in hotelList" :key="_item._id" :value="_item._id">
                                            <div style="display:flex">
                                                <span class="dateSelectHotelCell font-new">{{ _item.hotelname}}</span>
                                            </div>
                                        </a-select-option>
                                    </a-select>
                                    <div class="dateSelectHotelIconDiv">
                                        <a-icon class="dateSelectHotelIconCell" type="down" :style="isResortDropdown ? { fontSize: '20px', color: '#787878', 'transform':'rotate(180deg)'}:{ fontSize: '20px', color: '#787878', 'transform':'rotate(0deg)'}" />
                                    </div>
                                </div>
                                <!-- <div class="homeSelectText" @click="openHotelSelector">Select</div> -->
                            </div>
                            <div class="hotelDetailVerticalLine"></div>
                            <span class="dateSearchDiv">
                                <div class="dateSearchDivMain">
                                    <div class="dateSearchSubDiv">

                                    <date-range-picker
                                        class="dataRangePicker"
                                        ref="pickerMain"
                                        :opens="'center'"
                                        :locale-data="{ firstDay: 0, format: 'dd-mm-yyyy HH:mm:ss' }"
                                        :minDate="minDate" :maxDate="maxDate"
                                        :singleDatePicker="false"
                                        :timePicker="false"
                                        :timePicker24Hour="false"
                                        :showWeekNumbers="false"
                                        :showDropdowns="false"
                                        :autoApply="true"
                                        :ranges="false"
                                        :closeOnEsc="false"
                                        v-model="dateRange"
                                        :date-format="dateFormat"
                                        @update="updateValues"
                                        @start-selection="startSelection"
                                        @finish-selection="finishSelection"
                                        @toggle="dateToggle"
                                        :linkedCalendars="true"
                                    >
                                        <template v-slot:input="picker">
                                            <div @click="OnClickPicker">
                                                {{ `${picker.startDate != null? moment(picker.startDate).format("ddd MMM DD YYYY") : 'Check in '} - 
                                                ${picker.endDate != null? moment(picker.endDate).format("ddd MMM DD YYYY") : 'Check out '}`}}

                                            </div>
                                        </template>
                                    </date-range-picker>

                                    </div>
                                    <div class="dateSelectHotelIconDiv">
                                    <a-icon class="dateSelectHotelIconCell" type="down" :style="isDateDropdown ? { fontSize: '20px', color: '#787878', 'transform':'rotate(180deg)'}:{ fontSize: '20px', color: '#787878', 'transform':'rotate(0deg)'}" />
                                    </div>
                                </div>
                                
                                <!-- <div class="homeSelectText" @click="openDateSelector(true)">Select</div> -->
                            
                            </span>
                            <div class="hotelDetailVerticalLine"></div>
                            <div class="homeCountPickerDiv">
                            
                                <div class="homeCountPickerSubDiv">
                                    <div class="homeCountPickerSubDiv1" @click="showSearchCountPicker(true)">
                                        <span class="homeCountPickerSubText font-new">{{searchRoomInfo.length}} rooms, {{searchRoomInfo.reduce((accumulator, current) => accumulator + current.searchAdultCount, 0)}} adults, {{searchRoomInfo.reduce((accumulator, current) => accumulator + current.searchChildCount, 0)}} children</span> 
                                    </div>
                                    <div v-if="false" style="margin-top:auto;margin-bottom:auto;margin-right: 0;width: 250px;cursor:pointer;color:var(--title-color)" @click="showSearchCountPicker(true)">
                                    + Add Another Room
                                    </div>
                                    
                                    <div class="dateSelectHotelIconDiv">
                                    <a-icon class="dateSelectHotelIconCell" type="down" :style="isCountDropdown ? { fontSize: '20px', color: '#787878', 'transform':'rotate(180deg)'}:{ fontSize: '20px', color: '#787878', 'transform':'rotate(0deg)'}" />
                                    </div>
                                </div>
                                <!-- <div class="homeSelectText" @click="showSearchCountPicker(true)">Select</div> -->
                            </div>
                            <div class="hotelDetailVerticalLine"></div>
                            <div class="homeSearchBtnMobile" @click="gotoHotelSearchClick(true)">
                                <div style="display:flex;margin:auto">
                                    <div class="homeSearchBtnText font-new">SEARCH</div>
                                    <img class="homeSearchBtnIcon" src="../assets/images/icon_search.png" alt="" width="29px">
                                </div>
                            </div>    
                        </div>
                    </div>
                    
                    <a-modal
                        v-model="visibleSearchCountPicker"
                        title=""
                        :footer="null"
                    >
                        <div>
                        <person-count-selector-multi v-if="visibleSearchCountPicker"
                            :arr="searchRoomInfo" @getCountInfos="getCountInfos"></person-count-selector-multi>
                        </div>
                    </a-modal>
                    <a-modal
                        v-model="visibleSearchCountPickerLast"
                        title=""
                        :footer="null"
                    >
                        <div>
                            <person-count-selector-multi v-if="visibleSearchCountPickerLast" :isSearchClick="true"
                                :arr="searchRoomInfo" @getCountInfos="getCountInfosSearch"></person-count-selector-multi>
                        </div>
                    </a-modal>
                </div>
            </div>

        </lazy-component>
        <lazy-component class="hotelDetailDiv3">
            <div class="hotelDetailBottomDiv">
                <image-viewer :showBack="true" v-if="hotelImageListAll != undefined  && hotelImageListAll.length > 0" class="hotelDetailImageViwer" :imageData="hotelImageListAll" @showImageViewer="showImageViewer"></image-viewer>
                <div v-else class="hotelDetailImageGifDiv">
                    <img class="imageHotelDetail"
                    :src="'../images/logo.gif'" alt="" width="100%" height="100%">
                </div>
            </div>
            
            <a-modal
                class="hotelModel"
                v-model="visibleImageViewer"
                title=""
                :footer="null"
                :width="'auto'"
                :dialog-style="{ 'display': 'flex', 'align-items': 'center', 'max-width': '1080px', 'top': '0', 'height': '100%'}"
            >
                <image-previewer v-if="visibleImageViewer" :imageData="hotelImageListAll" :imageIndex="indexImageViewer" @close="showImageViewer(false)"/>
            </a-modal>
            
            <a-modal
                v-model="visibleMapViewer"
                title=""
                :footer="null"
                :width="'auto'"
                :dialog-style="{ 'display': 'flex', 'align-items': 'center', 'max-width': '1080px', 'top': '0', 'height': '100%'}"
            >
                <img v-if="visibleMapViewer" class="hotelMapViewImg" 
                    :src="hotelMapListAll.length > 0 && hotelMapListAll[0] != ''? `${hotelMapListAll[0]}`:''" draggable="false">
                
            </a-modal>
        </lazy-component>

        
        <lazy-component class="hotelDetailDiv4">
            <div class="hotelDetailContentDiv">
                <div class="hotelDetailContentMainDiv">
                    <div class="hotelDetailContentSubDiv1">
                        <div class="hotelDetailContentTopDiv">
                            <div class="hotelDetailContentLogoDiv">
                                <img class="imageHotelLogo"
                                    :src="selectedHotel.hotellogo != undefined? `${selectedHotel.hotellogo}`:'../images/logo.gif'">
                            </div>
                            <div class="hotelDetailContentTextDiv">
                                <div class="hotelDetailName font-new">{{selectedHotel.hotelname}}</div>
                                <div class="hotelDetailSubName font-new">{{selectedHotel.hotelproperty != undefined && selectedHotel.hotelproperty.location != undefined ? selectedHotel.hotelproperty.location : 'Raa Atol, Collection'}}</div>
                                <div class="hotelDetailSubTextDiv">
                                    <StarRating :max-rating="6" :rating="selectedHotel.star" :show-rating="false" :round-start-rating="false" v-bind:star-size="18" :active-on-click="true" :read-only="true" active-color="#FFC400" inactive-color="#E7E7E7"/>
                                    <a-popover placement="rightTop" class="StarOverDiv">
                                        <template slot="content">
                                            <div class="resortStarOverDivCell">
                                                Stars are on a 6-point scale indicating the level of luxury based on service, amenities, facilities & price. 6-stars indicate the highest level of luxury.
                                            </div>
                                        </template>
                                        <img style="margin-top: 0;margin-bottom: auto;margin-left: 5px;cursor:pointer;width:12px;object-fit: contain;" src="../assets/images/starInfo.png" alt="">
                                    </a-popover>
                                </div>
                            </div>
                        </div>
                        <div class="hotelDetailContentBottomDiv">
                            <div class="hotelFactBtn font-new" @click="gotoFactsheet">
                                Fact sheet
                            </div>
                            <div class="hotelMapBtn font-new" @click="gotoResortMap(true)">
                                Resort map
                            </div>
                        </div>
                    </div>
                    <div class="hotelDetailPropertyTitleMain">
                        <div class="hotelDetailPropertyTitle font-new">
                            Property Highlights
                        </div>
                        <div class="highlightsLine"></div>
                        <div class="hotelDetailPropertyMainDiv">
                            <div class="hotelDetailPropertyRowDiv">
                                
                                <div class="hotelDetailPropertyColDiv">
                                    <div class="hotelDetailPropertyColCellDiv">
                                        <img class="highlightsIcons" src="../assets/images/icon_map.png" alt="">
                                        <div style="margin-left:14px">
                                            <div class="hotelSevicesItemTitle font-new">How to get here</div>
                                            <div class="hotelSevicesItemDetail font-new">{{selectedHotel.hotelproperty == undefined ? '45 minutes seaplane' : selectedHotel.hotelproperty.getHere}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="hotelDetailPropertyColDiv">
                                    <div class="hotelDetailPropertyColCellDiv">
                                        <img class="highlightsIcons" src="../assets/images/icon_cooks.png" alt="">
                                        <div style="margin-left:14px">
                                            <div class="hotelSevicesItemTitle font-new">Dining and lounges</div>
                                            <div class="hotelSevicesItemDetail font-new">{{selectedHotel.hotelproperty == undefined ? '11' : selectedHotel.hotelproperty.diningLounges}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="hotelDetailPropertyRowDiv">
                                <div class="hotelDetailPropertyColDiv">
                                    <div class="hotelDetailPropertyColCellDiv">
                                        <img class="highlightsIcons" src="../assets/images/icon_hand.png" alt="">
                                        <div style="margin-left:14px">
                                            <div class="hotelSevicesItemTitle font-new">Best for</div>
                                            <div class="hotelSevicesItemDetail font-new">{{selectedHotel.hotelproperty == undefined ? '22 dining and 4 bars' : selectedHotel.hotelproperty.bestFor}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="hotelDetailPropertyColDiv">
                                    <div class="hotelDetailPropertyColCellDiv">
                                        <img class="highlightsIcons" src="../assets/images/icon_homes.png" alt="">
                                        <div style="margin-left:14px">
                                            <div class="hotelSevicesItemTitle font-new">Resort size</div>
                                            <div class="hotelSevicesItemDetail font-new">{{selectedHotel.hotelproperty == undefined ? selectedHotel.hoteldatarooms != undefined? selectedHotel.hoteldatarooms.numberofroom:'' : selectedHotel.hotelproperty.resortSize}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>         
            </div>
        </lazy-component>
        

        <lazy-component class="hotelDetailOverviewInfoDiv">
            <div class="resortTextDiv">
                <a-tabs default-active-key="1">
                    <a-tab-pane key="1" tab="Overview">
                        <div class="hotelDetailOverviewTabDiv">
                            <span v-html="selectedHotel.hotelOverview"></span>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="RESORT AMENITIES">
                        <div class="hotelDetailOverviewTabDiv">
                            <span v-html="selectedHotel.hotelAmenities"></span>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="VUE TIP">
                        <div class="hotelDetailOverviewTabDiv">
                            <span v-html="selectedHotel.hotelVueTip"></span>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="4" tab="CHILD POLICY">
                        <div class="hotelDetailOverviewTabDiv">
                            <span v-html="selectedHotel.hotelChildPolicy"></span>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>

            <!-- <div v-if="false" style="margin-left:4px;margin-right:4px;border: 1px solid #E7E7E7;margin-top:67px">
                <div style="margin-left: auto;margin-right: auto;max-width:1200px;">
                    <div style="width:100%;margin-top:20px;padding-left:20px;padding-right:20px;">
                        <span v-html="selectedHotel.hoteltext"></span>
                    </div>
                </div>
            </div> -->
        </lazy-component>

        <lazy-component class="hotelDetailMainInfoDiv" >
            <div class="roomListDiv">
                <a-list item-layout="horizontal" :data-source="rooms">
                    <a-list-item slot="renderItem" slot-scope="item, index">
                        <div class="roomListCellDiv">
                            <div class="roomListCellTopDiv">
                                <div class="roomListCellTopBackDiv">
                                    <div class="roomListCellTopBackground"></div>
                                </div>
                                <div class="roomListCellCarouselDiv">
                                    <VueSlickCarousel v-if="item.gallaryimages.length > 0"                                        
                                        class="roomListCellCarousel"
                                        :dots="false"
                                        :infinite="true"
                                        :speed="500"
                                        :slidesToShow="1"
                                        :slidesToScroll="1"
                                        @afterChange="afterPageChange($event, index)"
                                        :initialSlide="sliderStartIndex[index]"
                                        :autoplay="false"
                                        :arrows="false">
                                        <div v-for="_image, iImage in item.gallaryimages" :key="_image">
                                            <img class="sliderImageDetail" :src="_image != ''? `${_image}`:''" draggable="false" @click="showRoomImageViewer(true, iImage, item.gallaryimages)">
                                        </div>
                                    </VueSlickCarousel>
                                    <div v-else>
                                        <img class="sliderImageDetail" style="object-fit: none;" :src="'../images/logo.gif'" draggable="false">                                            
                                    </div>

                                    <div class="roomImagePrev">
                                        <a-icon class="nextBtnBackground" type="left" :style="{ fontSize: '40px', color:'white' }" @click="prevSlide(index, item.gallaryimages.length)"/>
                                    </div>
                                    <div class="roomImageNext">
                                        <a-icon class="nextBtnBackground" type="right" :style="{ fontSize: '40px', color:'white'  }" @click="nextSlide(index, item.gallaryimages.length)"/>
                                    </div>
                                    
                                    <div class="roomImageSearchIconDiv">
                                        <img class="roomImageSearchIcon" src="../assets/images/magnifying_glass.png" alt="" width="39px">
                                    </div>
                                </div>
                                <div class="roomListCelContentDiv">
                                    <div>
                                        <div class="hotelRoomTableName font-new">{{item.roomname}}
                                            <font class="hotelRoomTableNameChild" @click="showFloorPlan(index, false)" >(view floor plan)</font><br>                                        
                                        </div>
                                        <div>
                                            <div class="hotelRoomTableTextDiv" v-bind:style="[isShowRoomTextMore[index] ? {} : {'-webkit-line-clamp': '3', 'line-clamp': '3'}]">
                                                <span v-html="item.roomtext"></span>
                                            </div>
                                            <div class="hotelRoomTableTextBtn" @click="showRoomTextMore(item.roomtext)">+more</div>
                                            <!-- <div v-if="isShowRoomTextMore[index]" class="hotelRoomTableTextBtn" @click="showRoomTextMore(index, false)">-hide</div> -->
                                            
                                        </div>
                                        <div class="roomCardtypeDetail">
                                            <div class="roomCardtypeDetailCellDiv">
                                                <div class="roomCardtypeDetailCellImgDiv">
                                                    <img class="roomItemIcon" src="../assets/images/room_size_icon.png">
                                                </div>
                                                
                                                <div class="roomCardtypeDetailCellContentDiv">
                                                    <div class="roomItemTitle">
                                                        Room Size:
                                                    </div>
                                                    <div class="roomItemDetail">
                                                        {{item.roomSize}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="roomCardtypeDetailCellDiv">
                                                <div class="roomCardtypeDetailCellImgDiv">
                                                    <img class="roomItemIcon" src="../assets/images/standard_icon.png">
                                                </div>
                                                <div class="roomCardtypeDetailCellContentDiv">
                                                    <div class="roomItemTitle">
                                                        Standard Guests:
                                                    </div>
                                                    <div class="roomItemDetail">
                                                        {{item.standardOccupancy}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="roomCardtypeDetailCellDiv">
                                                <div class="roomCardtypeDetailCellImgDiv">
                                                    <img class="roomItemIcon" src="../assets/images/max_peaple_icon.png">
                                                </div>
                                                <div class="roomCardtypeDetailCellContentDiv">
                                                    <div class="roomItemTitle">
                                                        Max Guests:
                                                    </div>
                                                    <div class="roomItemDetail">
                                                        {{item.maxOccupancy}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="roomCardtypeDetailCellDiv">
                                                <div class="roomCardtypeDetailCellImgDiv">
                                                    <img class="roomItemIcon" src="../assets/images/bedding_icon.png">
                                                </div>
                                                <div class="roomCardtypeDetailCellContentDiv">
                                                    <div class="roomItemTitle">
                                                        Bedding:
                                                    </div>
                                                    <div class="roomItemDetail">
                                                        {{item.bedding}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="roomCardtypeDetailCellDiv">
                                                <div class="roomCardtypeDetailCellImgDiv">
                                                    <img class="roomItemIcon" src="../assets/images/add_bed_icon.png">
                                                </div>
                                                <div class="roomCardtypeDetailCellContentDiv">
                                                    <div class="roomItemTitle">
                                                        Additional Beds:
                                                    </div>
                                                    <div class="roomItemDetail">
                                                        {{item.additionalBeds}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-top:30px">
                                        <!-- <div class="hotelDetailPriceTitle3">{{selectedDays}} nights</div> -->
                                        <div v-show="false" class="hotelRoomTablePrice2">{{selecteBookingInfo[item._id] != undefined && selecteBookingInfo[item._id].mainPriceSub != undefined && selecteBookingInfo[item._id].mainPriceSub != 0
                                                        ? currencyName + " " + addZeroes(Math.round((selecteBookingInfo[item._id].mainPriceSub) * currency * 100) / 100) : 'Request Basis'}}</div>
                                        <!-- <div v-if="item.mainprice != 0" class="hotelDetailPriceTitle4">{{currencySign}}{{addZeroes(Math.round((cellRate.totalprice != undefined? cellRate.totalprice : 0) * currency * 100) / 100)}}</div>
                                        <div v-else class="hotelDetailPriceTitle4">Request Basis</div> -->

                                        <div class="hotelDetailPriceTitle6">Taxes & service charges included</div>
                                        <div class="hotelDetailPriceTitle6">Roundtrip airport transfers included</div>
                                    </div>
                                    
                                    <div class="hotelViewBtnsDiv">
                                        <div class="roomAmenitiesBtn" @click="gotoRoomAmenities(index)">
                                            Room Amenities
                                        </div>
                                        <div class="hotelViewRatesBtn" @click="showRates(index)">
                                            {{isShowRates[index] ? 'HIDE RATES':'BOOK NOW'}}
                                        </div>
                                    </div>

                                    
                                    <a-modal 
                                        v-model="visibleFloorPlan[index]"
                                        title=""
                                        :footer="null"
                                        :width="'auto'"
                                        :dialog-style="{ 'display': 'flex', 'align-items': 'center', 'max-width': '1080px', 'top': '0', 'height': '100%'}"
                                    >
                                        <room-plan-previewer v-if="visibleFloorPlan[index]" :imageData="item.roomfloorplans" :imageIndex="0" @close="showFloorPlan(index, false)"/>
                                            <!-- <img style="display: block;" width="100%" height="100%" :src="item.roomfloorplans != undefined && item.roomfloorplans.length > 0 && item.roomfloorplans[0] != ''? `${item.roomfloorplans[0]}`:''" draggable="false"> -->
                                    </a-modal>
                                    
                                    <a-modal 
                                        v-model="visibleRoomAmenities[index]"
                                        title=""
                                        :footer="null"
                                        :closable="true"
                                        :width="$mq === 'lg' || $mq === 'md' ? '800px' : ''"
                                    >
                                    
                                        <div class="roomAmenitiesTitleMain">
                                            <div class="roomAmenitiesTitle">Room Amenities</div>
                                            <div class="roomAmenitiesTitleSubDiv">
                                                <div class="hotelDetailText">
                                                    <div v-for="cell in item.facilitiesInfo != undefined ? item.facilitiesInfo.slice(0, Math.ceil(item.facilitiesInfo.length / 2)) : []" :key="cell">
                                                        • {{cell}}
                                                    </div>
                                                </div>
                                                <div class="hotelDetailText">
                                                    <div v-for="cell in item.facilitiesInfo != undefined ? item.facilitiesInfo.slice(Math.ceil(item.facilitiesInfo.length / 2)) : []" :key="cell">
                                                        • {{cell}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <room-plan-previewer v-if="visibleFloorPlan[index]" :imageData="item.roomfloorplans" :imageIndex="0" @close="showFloorPlan(index, false)"/> -->
                                            <!-- <img style="display: block;" width="100%" height="100%" :src="item.roomfloorplans != undefined && item.roomfloorplans.length > 0 && item.roomfloorplans[0] != ''? `${item.roomfloorplans[0]}`:''" draggable="false"> -->
                                    </a-modal>
                                </div>
                            </div>
                            
                            <div v-if="isShowRates[index]">
                                <div v-for="(cellRate, rateindex) in showedPromotions" :key="cellRate._id">
                                    <div class="hotelDetailRoomPriceDiv">
                                        <div class="hotelDetailRoomPriceDiv1">
                                            <div class="hotelDetailPriceTitle1">{{cellRate.title}}</div>
                                            <div class="hotelDetailPriceTitle2" @click="showInclusion(rateindex)">Inclusions</div>
                                            <div class="hotelDetailPriceTitle2" @click="showTermsCondition(rateindex)">Terms & Conditions</div>
                                        </div>
                                        <div class="hotelDetailRoomPriceDiv2">
                                            <!-- <div class="hotelDetailPriceTitleCell">{{selectedDays}} nights</div> -->
                                            <!-- <div class="hotelDetailPriceTitle4">{{currencySign}}{{addZeroes(Math.round((selecteBookingInfo[item._id] != undefined? selecteBookingInfo[item._id].subPriceInfo[cellRate._id].subPrice : 0) * currency * 100) / 100)}}</div> -->
                                            <!-- <div class="hotelDetailPriceTitle5" @click="clickRateBreakdown(index, rateindex)">Rate breakdown</div> -->
                                        </div>
                                        <div class="hotelDetailRoomPriceBtnDiv">
                                            <button class="hotelDetailRoomSelect" @click="gotoCheckout(index,rateindex)">SELECT</button>
                                        </div>
                                    </div>
                                    <div v-show="isShowRateBreakdown[index] != undefined ? isShowRateBreakdown[index][rateindex] : false">
                                        <div class="inspirationLine"></div>
                                        <div style="display:flex">
                                            <div class="hotelDetailTotalPrice1Div">
                                                <div class="hotelDetailTotalPrice1">RATE INCLUDES ({{currencyName}} currency)</div>
                                                <div class="hotelDetailTotalPriceDiv">
                                                    <div class="hotelDetailTotalPrice2">Green tax per person, per day ($6):</div>
                                                    <div class="hotelDetailTotalPrice3">{{currencySign}}{{addZeroes(Math.round((selecteBookingInfo[item._id] != undefined? selecteBookingInfo[item._id].subPriceInfo[cellRate._id].greenTax : 0) * currency * 100) / 100)}}</div>
                                                </div>
                                                <div class="hotelDetailTotalPriceDiv">
                                                    <div class="hotelDetailTotalPrice2">GST & Service charges:</div>
                                                    <div class="hotelDetailTotalPrice3">{{currencySign}}{{addZeroes(Math.round((selecteBookingInfo[item._id] != undefined? selecteBookingInfo[item._id].subPriceInfo[cellRate._id].gstService : 0) * currency * 100) / 100)}}</div>
                                                </div>
                                                <div class="hotelDetailTotalPriceDiv">
                                                    <div class="hotelDetailTotalPrice2">Extra adult charge(includes green tax):</div>
                                                    <div class="hotelDetailTotalPrice3">{{currencySign}}{{addZeroes(Math.round((selecteBookingInfo[item._id] != undefined? selecteBookingInfo[item._id].subPriceInfo[cellRate._id].extraAdultCharge : 0) * currency * 100) / 100)}}</div>
                                                </div>
                                                <div class="hotelDetailTotalPriceDiv">
                                                    <div class="hotelDetailTotalPrice2">Extra child charge(includes green tax):</div>
                                                    <div class="hotelDetailTotalPrice3">{{currencySign}}{{addZeroes(Math.round((selecteBookingInfo[item._id] != undefined? selecteBookingInfo[item._id].subPriceInfo[cellRate._id].extraChildCharge : 0) * currency * 100) / 100)}}</div>
                                                </div>
                                                <div v-if="permissionLevel >= 4" class="hotelDetailTotalPriceDiv">
                                                    <div class="hotelDetailTotalPrice2" style="color:red">{{selectedHotel.hoteldatageneral != undefined && selectedHotel.hoteldatageneral.commission != undefined ? Math.floor(selectedHotel.hoteldatageneral.commission * 100) : 14}}% commission:</div>
                                                    <div class="hotelDetailTotalPrice3" style="color:red">{{currencySign}}{{addZeroes(Math.round((selecteBookingInfo[item._id] != undefined? selecteBookingInfo[item._id].subPriceInfo[cellRate._id].commission : 0) * currency * 100) / 100)}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inspirationLine"></div>
                                </div>
                                
                                <a-modal
                                    class="InclusionDiv"
                                    v-model="visibleInclusion"
                                    title=""
                                    :footer="null"
                                    :closable="true"
                                    :width="$mq === 'lg' || $mq === 'md' ? '800px' : ''"
                                >                                
                                    <div class="InclusionSubDiv">
                                        <div class="InclusionSubDivTitle">{{visibleInclusionTitle}}</div>
                                        <div class="InclusionSubDivDetail">{{inclusionText}}</div>
                                    </div>
                                </a-modal>
                                
                            </div>
                        </div>
                        
                        
                    </a-list-item>
                </a-list>
            </div>
            
            <a-modal
                v-model="visibleDetailItem"
                title=""
                :footer="null"
                :closable="true"
            >            
                <span v-html="detailItemText"></span>
                <!-- <div style="white-space: pre-wrap;">{{detailItemText}}</div> -->
            </a-modal>
            
            <a-modal
                class="hotelRoomImageModel"
                v-model="visibleRoomImageViewer"
                title=""
                :footer="null"
                :width="'auto'"
                :dialog-style="{ 'display': 'flex', 'align-items': 'center', 'max-width': '1080px', 'top': '0', 'height': '100%'}"
            >
                <room-image-previewer v-if="visibleRoomImageViewer" :imageData="roomImageListAll" :imageIndex="indexRoomImageViewer" @close="showRoomImageViewer(false)"/>
            </a-modal>
        </lazy-component>
            
        <contactus-viewer></contactus-viewer>
        <instagram-viewer style="padding-bottom: 100px;"></instagram-viewer>    
    </div>

</template>

<script>
import { api } from '../helpers/Helpers';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import moment from 'moment';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ImagePreviewer from './ListItems/image-previewer.vue';
// import PersonCountSelector from './SearchDialogs/PersonCountSelector.vue';
// import { common } from '../helpers/Common';
// import { bus } from '../main'
import PersonCountSelectorMulti from './SearchDialogs/PersonCountSelectorMulti.vue';
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import RoomImagePreviewer from './ListItems/room-image-previewer.vue';
import StarRating from 'vue-star-rating';
import RoomPlanPreviewer from './ListItems/room-plan-previewer.vue';
import ImageViewer from './ListItems/image-viewer.vue';
import ContactusViewer from './ListItems/contactus-viewer.vue';
import InstagramViewer from './ListItems/instagram-viewer.vue';

const imageUrls = [
  {
    url: 'https://cdn.vuetifyjs.com/images/john.jpg',
  },
  {
    url: 'https://cdn.vuetifyjs.com/images/john.jpg',
  },
  {
    url: 'https://cdn.vuetifyjs.com/images/john.jpg',
  },
  {
    url: 'https://cdn.vuetifyjs.com/images/john.jpg',
  },
];
const rooms = [];
const hotelList = [];
export default {
    
    data() {
        return {

            imageUrls,
            rooms,
            hotelId:'',
            selectedHotel:{},
            hotelImageMain:'',
            hotelImageListAll:[],
            selecteBookingInfo:{},

            visibleImageViewer: false,
            indexImageViewer: 0,
            isShowRoomMore:new Array(),
            isShowRoomTextMore:new Array(),
            visibleFloorPlan: new Array(),
            visibleFloorPlanMobile: new Array(),
            isShowRates: new Array(),
            visibleRoomAmenities: new Array(),
            inclusionText: '',
            visibleInclusionTitle: '',
            visibleInclusion: false,
            
            visibleRoomImageViewer: false,
            roomImageListAll:[],
            indexRoomImageViewer: 0,

            visibleDetailItem:false,
            detailItemText:'',

            
            visibleMapViewer: false,
            hotelMapListAll:[],
        
            visibleSearchCountPicker:false,
            visibleSearchCountPickerLast:false,

            searchRoomCount:0,
            searchAdultCount:0,
            searchChildCount:0,
            searchChildAge:0,
            
            searchRoomInfo:[],
            searchRoomInfoUse:[],
            searchRoomInfoID: 0, 

            searchStartDateUse:null,
            searchEndDateUse:null,
            searchStartDate:null,
            searchEndDate:null,
            visibleRoomList:false,
            visibleRoomListMobile:false,

            currency:1.0,
            currencyName:'USD',
            currencySign:'$',

            selectedDays: 1,

            showedPromotions:[],
            isShowRateBreakdown:[],
            
            permissionLevel: 1,
      
            // dateFormat : (val) => {
            //     return moment(val).format('ddd MMM DD YYYY')// HH:mm:ss')
            // },
      
            dateRange:{
                startDate: null,
                endDate: null
            },
            
            minDate:new Date(),
            maxDate:null,
            
            hotelList,
            selHotelInfo: [],

            
            isResortDropdown:false,
            isDateDropdown:false,
            isCountDropdown:false,

            showSearchFilterMobile:false,
            
            pickerResortsOpen:false,
            sliderStartIndex:[],
        };
    },
    components: {
        ImagePreviewer,
        VueSlickCarousel,
        PersonCountSelectorMulti,
        DateRangePicker,
        RoomImagePreviewer,
        StarRating,
        RoomPlanPreviewer,
        ImageViewer,
        ContactusViewer,
        InstagramViewer
        // PersonCountSelector
    },
    created(){
        this.initDate();
    },
    mounted(){

    },
    methods: {
        moment,
        initDate:async function(){
            this.currency = this.$store.state.selectedCurrency.rate;
            this.currencyName = this.$store.state.selectedCurrency.name;
            this.currencySign = this.$store.state.selectedCurrency.sign;
        
            if (this.$store.state.loginUser !== null){
                const loginType = this.$store.state.loginUser.loginType;
                this.permissionLevel = this.$store.state.loginUser[loginType].permissionLevel;
            }
            
            await this.getHotelList();
            await this.initHotel();
        },
        getHotelList:async function(){
            this.loading = true;
            const res = await api.getHotelsTotal();
            
            res.sort(function(a,b) {
                let aValue = a['hotelname'];
                let bValue = b['hotelname'];
                return (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0);
                } 
            );
            this.hotelList = [
                {
                    _id:"default",
                    hotelname:"View All"
                }
            ];
            res.forEach((item)=>{
                if(!item.hotelHidden){
                this.hotelList.push(item);
                }
             })
            // this.hotelList = this.hotelList.concat(res);
            
            if (this.searchStartDate != null && this.searchEndDate != null){
                this.onClickSearch();
            }
        },
        
        handleHotelNameChange(){
            if (this.selHotelInfo.length > 0 && this.selHotelInfo[this.selHotelInfo.length - 1] == "default"){
                this.selHotelInfo = ["default"];
                return;
            }
            for (let i = 0; i < this.selHotelInfo.length; i++){
                if (this.selHotelInfo[i] == "default"){
                    this.selHotelInfo.splice(i, 1);
                }
                var checkList = this.hotelList.filter(obj => obj._id == this.selHotelInfo[i])
                if (checkList.length <= 0){
                    this.selHotelInfo.splice(i, 1);
                }
            }
        },
        filterOption(input, option) {
            const hotelInfo = this.hotelList.filter(obj => obj._id == option.key);
            if (hotelInfo.length > 0){
                return hotelInfo[0].hotelname.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            return false;
        },
        
        onBlurHotel() {
            for (let i = 0; i < this.selHotelInfo.length; i++){
                const hotelInfo = this.hotelList.filter(obj => obj._id == this.selHotelInfo[i]);
                if (hotelInfo.length <= 0){
                    this.selHotelInfo.splice(i, 1);
                }
            }
        }, 
        dropdownHotelChange(_isDropdown){
            this.isResortDropdown = _isDropdown;
            this.pickerResortsOpen = _isDropdown;
        },

        updateValues(aDate){
            
            this.searchStartDate = aDate.startDate.getTime();//moment(aDate.startDate).format("YYYY-MM-DD");
            this.searchEndDate = aDate.endDate.getTime();//moment(aDate.endDate).format("YYYY-MM-DD");
        },
        
        startSelection(aDate){
            this.selectedStartDate = aDate;
        },
        finishSelection(aDate){
            this.selectedEndDate = aDate;
        },
        
        dateToggle(dateToggle){
            
            this.selectedStartDate = null;
            this.selectedEndDate = null;
            if (!this.isDateDropdown && !dateToggle){
                this.$refs.pickerMain.togglePicker(true);
                this.isDateDropdown = true;
            }
            else{
                this.isDateDropdown = dateToggle;
            }
        },
        OnClickPicker(){
            // this.selectedStartDate = null;
            // this.selectedEndDate = null;
        },
        openHotelSelector(){
            this.$refs.pickerResorts.blur();
            this.pickerResortsOpen = true;  
            this.isResortDropdown = true;  
            setTimeout(() => {
            this.$refs.pickerResorts.focus();
            }, 50);
        },
        openDateSelector(isShow){
            this.$refs.pickerMain.togglePicker(isShow);
        },
        dateFormat (classes, date) {
            if (!classes.disabled) {
            classes.disabled = date.getTime() < this.selectedStartDate
            }
            return classes
        },
        
        clickSearchFilterMobile(){
            this.showSearchFilterMobile = !this.showSearchFilterMobile;
        },

        addZeroes(num){
            let textNum = num.toString();
            let decimalResult = Number(textNum).toFixed(Math.max(textNum.split('.')[1]?.length, 2) || 2);
            let thousand = decimalResult.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return thousand;
        },
        initHotel:async function(){
            const name = this.$route.params.hotelname;
            // const _id ='623a353a24923ce6b8bf7b43';
            this.searchStartDate = this.$route.query.searchStartDate;
            this.searchStartDateUse = this.$route.query.searchStartDate;
            this.searchEndDate = this.$route.query.searchEndDate;
            this.searchEndDateUse = this.$route.query.searchEndDate;

            if (this.$route.query.searchRoomInfo != undefined){
                this.searchRoomInfo = JSON.parse(this.$route.query.searchRoomInfo);
                this.searchRoomInfoUse = JSON.parse(this.$route.query.searchRoomInfo);
            }
            else {
                this.searchRoomInfo =[{
                    searchAdultCount : 2,
                    searchChildCount : 0,
                    searchChildAge : []
                }]
                this.searchRoomInfoUse =[{
                    searchAdultCount : 2,
                    searchChildCount : 0,
                    searchChildAge : []
                }]
            }
            if (this.$route.query.searchRoomInfoID != undefined)
                this.searchRoomInfoID = parseFloat(this.$route.query.searchRoomInfoID);
            else
                this.searchRoomInfoID = 0;

            if (name != null){
                this.selectedHotel = await api.getHotel(name);
                this.hotelId = this.selectedHotel._id;
                this.selHotelInfo = [this.hotelId];
                // const hotelText = document.querySelector('#hoteltextid');
                // hotelText.innerHTML = this.selectedHotel.hoteltext;
                this.hotelImageListAll = [];
                if (this.selectedHotel.hotelimage != undefined && this.selectedHotel.hotelimage != '')
                    this.hotelImageMain = this.selectedHotel.hotelimage;
                if (this.selectedHotel.gallaryimages.length > 0)
                    this.hotelImageListAll = this.hotelImageListAll.concat(this.selectedHotel.gallaryimages)
                this.hotelMapListAll = [];
                this.hotelMapListAll.push(this.selectedHotel.hotelmap);
                this.getHotelRooms(this.hotelId);
            }
        },
        
        getHotelRooms:async function(_hotelId){
            const res = await api.getHotelRooms(_hotelId);
            this.getHotelRoomsResult(res);
            this.getHotelBooking(_hotelId);
        },
        getHotelRoomsResult(res){
            this.rooms = [];
            for(let i = 0; i < res.length; i ++){
                
                if (res[i].standardmin != undefined && res[i].standardmin != null && res[i].standardmax != undefined && res[i].standardmax != null){
                    if (this.searchRoomInfo[this.searchRoomInfoID].searchAdultCount + this.searchRoomInfo[this.searchRoomInfoID].searchChildCount > res[i].standardmin 
                        || this.searchRoomInfo[this.searchRoomInfoID].searchAdultCount > res[i].standardmax)
                        continue;
                    let adultchildnummberList = res[i].adultchildnummber;
                    let matchCountCondition = true;
                    for (let element of adultchildnummberList) {
                        if (element.adultnumber == this.searchRoomInfo[this.searchRoomInfoID].searchAdultCount && element.childnumber == this.searchRoomInfo[this.searchRoomInfoID].searchChildCount){
                            matchCountCondition = false;
                            let isCondtion = true;
                            for(let j = 0 ; j < this.searchRoomInfo[this.searchRoomInfoID].searchChildCount; j++){
                                if (this.searchChildAge[j] > element.childageto)
                                    isCondtion = false;
                            }
                            if (isCondtion){
                                matchCountCondition = true;
                                break;
                            }
                        }
                    }
                    if (!matchCountCondition)
                        continue;
                }
                this.sliderStartIndex.push(0);
                this.rooms.push(res[i]);
                this.isShowRoomMore.push(false);
                this.isShowRoomTextMore.push(false);
                this.isShowRates.push(false);
                this.visibleRoomAmenities.push(false);
                this.visibleFloorPlan.push(false);
                this.visibleFloorPlanMobile.push(false);
            }

        },

        getHotelBooking:async function(_hotelId){
            let days = 2;
            this.selectedDays = days
            let fromDate = new Date();
            let toDate = new Date();
            toDate.setDate(toDate.getDate() + days - 1);
            if (this.searchStartDate != null && this.searchEndDate != null){
                fromDate = new Date(this.searchStartDate);
                toDate = new Date(this.searchEndDate);
                
                const diffTime = Math.abs(toDate - fromDate);
                this.selectedDays = Math.round(diffTime / (1000 * 60 * 60 * 24)); 
            }
            const curDate = new Date();
            const advanceDay = Math.floor(Math.abs(fromDate - curDate) / (1000 * 60 * 60 * 24)); 

            const _Info = {
                hotelId:_hotelId,
                fromdate: fromDate,
                todate: toDate,
            }
            const res = await api.getResortBooking(_Info);
            const _bookingPriceInfo = res;
            const _keys = Object.keys(_bookingPriceInfo);
            const _hotelInfo = this.selectedHotel;
            
            const extraPersonInfo = _hotelInfo.extraperson;
            
            let greenTaxUnit = 6;
            if (_hotelInfo.hoteldatageneral != undefined && _hotelInfo.hoteldatageneral.greentax != undefined && _hotelInfo.hoteldatageneral.greentax != null)
                greenTaxUnit = _hotelInfo.hoteldatageneral.greentax;

            let extraPrice = 0;
            for(let _index = 0; _index < this.searchChildAge.length; _index++){
                let extraPriceInfo = extraPersonInfo.filter(obj => obj.agerange.from <= this.searchChildAge[_index] && obj.agerange.to >= this.searchChildAge[_index])
                let childPrice = 90;
                if (extraPriceInfo.length > 0){
                    childPrice = extraPriceInfo[0].rate.perday;
                }
                extraPrice += childPrice * this.selectedDays;
                if (!extraPriceInfo[0].rate.includegreentax){
                    extraPrice += greenTaxUnit * this.selectedDays;
                }
            }

            this.selecteBookingInfo = {}
            for(let i = 0; i < _keys.length; i++){
                let mainPriceList = _bookingPriceInfo[_keys[i]];
                let mainPrice = 0;
                mainPriceList.forEach(element => {
                    mainPrice += element;
                });
                let mainPriceSub = 0;
                let maxPrice = 0;

                let roomPriceInfo = {};
                this.showedPromotions = [];
                // this.isShowRateBreakdown = [];
                if (_hotelInfo.promotion.length > 0){
                    for(let j = 0; j < _hotelInfo.promotion.length; j++){
                        
                        // showedPromotions
                        if (_hotelInfo.promotion[j].minnights != undefined && _hotelInfo.promotion[j].maxnights != undefined && 
                            (this.selectedDays < _hotelInfo.promotion[j].minnights || this.selectedDays > _hotelInfo.promotion[j].maxnights)){
                            continue;
                        }
                        if (_hotelInfo.promotion[j].advance != undefined && advanceDay < _hotelInfo.promotion[j].advance){
                            continue;
                        }
                        this.showedPromotions.push(_hotelInfo.promotion[j]);
                        // this.isShowRateBreakdown.push(false);


                        let subPrice = mainPrice;
                        if (_hotelInfo.promotion[j].discount != undefined){
                            subPrice = subPrice * (1 - _hotelInfo.promotion[j].discount / 100);
                        }
                        else if (_hotelInfo.promotion[j].stayoffer != undefined && _hotelInfo.promotion[j].stayoffer.stayday != undefined 
                            && _hotelInfo.promotion[j].stayoffer.offerday != undefined && _hotelInfo.promotion[j].stayoffer.stayday != 0 && _hotelInfo.promotion[j].stayoffer.offerday != 0){
                            const _stayday = _hotelInfo.promotion[j].stayoffer.stayday;//7
                            const _offerDay = _hotelInfo.promotion[j].stayoffer.offerday;//5
                            subPrice = 0;
                            for (let k = 0; k < mainPriceList.length; k++){
                                if (k % _stayday > _offerDay - 1)
                                    continue;
                                subPrice += mainPriceList[k]
                            }
                        }


                        if (_hotelInfo.hoteldatageneral.publishedrate != undefined && _hotelInfo.hoteldatageneral.publishedrate != 0){
                            subPrice = subPrice * _hotelInfo.hoteldatageneral.publishedrate;
                        }
                        let totalprice = subPrice;
                        let greenTax = greenTaxUnit * this.searchRoomInfo[this.searchRoomInfoID].searchAdultCount * this.selectedDays;
                        if (_hotelInfo.hoteldatageneral.calculationfomula == "Formula1"){
                            subPrice = subPrice + greenTax;
                        }

                        let extraChildCharge = extraPrice;// * this.searchChildCount;
                        subPrice = subPrice + extraChildCharge;
                        
                        // let totalprice = subPrice;
                        // let gst = 0;
                        if ( _hotelInfo.hoteldatageneral.gst != undefined && _hotelInfo.hoteldatageneral.gst != 0){
                            const tempSubPrice = totalprice / _hotelInfo.hoteldatageneral.gst;
                            // gst = totalprice - tempSubPrice;
                            // gst = tempSubPrice;
                            totalprice = tempSubPrice;
                        }
                        let servicecharges = 0;
                        if ( _hotelInfo.hoteldatageneral.servicecharges != undefined && _hotelInfo.hoteldatageneral.servicecharges != 0){
                            const tempSubPrice = totalprice / _hotelInfo.hoteldatageneral.servicecharges;
                            // servicecharges = totalprice - tempSubPrice;
                            servicecharges = tempSubPrice;
                            totalprice = tempSubPrice;
                        }
                        let commission = 0;
                        if ( _hotelInfo.hoteldatageneral.commission != undefined && _hotelInfo.hoteldatageneral.commission != 0){
                            const tempSubPrice = totalprice * _hotelInfo.hoteldatageneral.commission;
                            // commission = totalprice - tempSubPrice;
                            commission = tempSubPrice;
                            totalprice = tempSubPrice;
                        }


                        if (mainPriceSub == 0 || mainPriceSub > subPrice)
                            mainPriceSub = subPrice;
                        if (maxPrice == 0 || maxPrice < subPrice)
                            maxPrice = subPrice;
                        let _infoCellPromotion = {
                            subPrice: subPrice,
                            greenTax: greenTax,
                            gstService: servicecharges,
                            commission: commission,
                            extraAdultCharge: 0,
                            extraChildCharge: extraChildCharge

                        }
                        roomPriceInfo[_hotelInfo.promotion[j]._id] = _infoCellPromotion;
                    }
                }
                else{
                    this.showedPromotions = [];
                    this.showedPromotions.push({
                        description: "-\tComplimentary upgrade from Earth beach villa to Earth villa with pool or Overwater Wind villa to Overwater villa with pool\n-\tReturn (roundtrip) transfers – 45 minutes via speedboat for two person\n-\tDaily champagne breakfast with a wide variety of cuisine; assorted selection of cheeses, smoked meats, cold cuts; exotic fruits.\n-\tDaily lunch with selection of light meals and snacks.\n-\tDaily dinner dine-around fine dining experiences which includes international cuisine, theme nights, authentic south Asian specialty cuisine from India and Sri Lanka; Specialty Chinese cuisine.\n-\tAll day delectable choice of snacks and pizza\n-\tPremium selection of select champagne, whiskeys, international beers and a wide selection of branded spirits.\nExotic wine collection of over 80 labels of Old and New World.\n-\tUnlimited international popular cocktails; selection of exotic teas and coffee varieties\n-\tOne-time fine dining at Underwater specialty restaurant. M6M. (based on 4 nights minimum stay)\n-\tIn-villa mini bar replenished twice daily\n-\tFull access to fitness and recreation centre services. (1) adventure excursion per stay; (1) sunset fishing excursion per day; Unlimited snorkeling excursions\n-(1) 60-minute massage or dive (staying min. 4 nights). Staying for min. of 6 nights will receive (2) massages or dive, per person\n-\tTax & Services, Green tax included in rate",
                        termsconditions: "Credit card & a valid form of ID will be required at time of booking, to guarantee your booking. Payments will be collected based on the terms below. \n\nNow - 31st October 2021:\nPayment Terms: \n100% due 10 days prior to check-in\nCancellation Policy:\nLess than 7 days to arrival, 100% penalty\n\nFestive period (20th Dec - 10th Jan, 2022) \nPayment Terms: \n100% due 40 days prior to check-in\nCancellation Policy:\nLess than 30 days to arrival, 100% penalty\n\nAll remaining dates (excluding festive period)\nPayment Terms: \n100% due 20 days prior to check-in\nCancellation Policy:\nLess than 14 days to arrival, 100% penalty",
                        title: "Standard Rate ",
                        _id: "standard"
                    });
                    
                    let subPrice = mainPrice;
                    if (_hotelInfo.hoteldatageneral.publishedrate != undefined && _hotelInfo.hoteldatageneral.publishedrate != 0){
                        subPrice = subPrice * _hotelInfo.hoteldatageneral.publishedrate;
                    }
                    let totalprice = subPrice;
                    let greenTax = greenTaxUnit * this.searchRoomInfo[this.searchRoomInfoID].searchAdultCount * this.selectedDays;
                    if (_hotelInfo.hoteldatageneral.calculationfomula == "Formula1"){
                        subPrice = subPrice + greenTax;
                    }

                    let extraChildCharge = extraPrice;// * this.searchChildCount;
                    subPrice = subPrice + extraChildCharge;
                    
                    // let totalprice = subPrice;
                    // let gst = 0;
                    if ( _hotelInfo.hoteldatageneral.gst != undefined && _hotelInfo.hoteldatageneral.gst != 0){
                        const tempSubPrice = totalprice / _hotelInfo.hoteldatageneral.gst;
                        // gst = totalprice - tempSubPrice;
                        // gst = tempSubPrice;
                        totalprice = tempSubPrice;
                    }
                    let servicecharges = 0;
                    if ( _hotelInfo.hoteldatageneral.servicecharges != undefined && _hotelInfo.hoteldatageneral.servicecharges != 0){
                        const tempSubPrice = totalprice / _hotelInfo.hoteldatageneral.servicecharges;
                        // servicecharges = totalprice - tempSubPrice;
                        servicecharges = tempSubPrice;
                        totalprice = tempSubPrice;
                    }
                    let commission = 0;
                    if ( _hotelInfo.hoteldatageneral.commission != undefined && _hotelInfo.hoteldatageneral.commission != 0){
                        const tempSubPrice = totalprice * _hotelInfo.hoteldatageneral.commission;
                        // commission = totalprice - tempSubPrice;
                        commission = tempSubPrice;
                        totalprice = tempSubPrice;
                    }

                    let _infoCellPromotion = {
                        subPrice: subPrice,
                        greenTax: greenTax,
                        gstService: servicecharges,
                        commission: commission,
                        extraAdultCharge: 0,
                        extraChildCharge: extraChildCharge

                    }
                    roomPriceInfo["standard"] = _infoCellPromotion;
                    
                    if (mainPriceSub == 0 || mainPriceSub > subPrice)
                        mainPriceSub = subPrice;
                    if (maxPrice == 0 || maxPrice < subPrice)
                        maxPrice = subPrice;
                }
                this.selecteBookingInfo[_keys[i]] = 
                {
                    mainPrice: mainPrice,
                    mainPriceSub: mainPriceSub,
                    maxPrice: maxPrice,
                    subPriceInfo: roomPriceInfo
                };
            }
            // this.selecteBookingInfo = res;
            window.scrollTo(0,0);
        },
        clickRateBreakdown(totalIndex, index){
            
            if (this.isShowRateBreakdown.length <= totalIndex){
                for(let i = this.isShowRateBreakdown.length; i <= totalIndex; i++)
                    this.isShowRateBreakdown[i] = [];
            }
            if(this.isShowRateBreakdown[totalIndex].length <= index){
                for(let i = this.isShowRateBreakdown[totalIndex].length; i <= index; i++)
                    this.isShowRateBreakdown[totalIndex][i] = false;
            }
            var tempInfo = this.isShowRateBreakdown[totalIndex];
            tempInfo.splice(index, 1, !tempInfo[index]);
            this.isShowRateBreakdown.splice(totalIndex, 1, tempInfo);

        },
        
        showImageViewer(isShow, index){
            if (this.hotelImageListAll != undefined){
                this.visibleImageViewer = isShow && this.hotelImageListAll.length > 0;
                this.indexImageViewer = index;
            }
        },
        showRoomImageViewer(isShow, index, _imageList){
            if (isShow){
                this.visibleRoomImageViewer = isShow && _imageList.length > 0;
                this.roomImageListAll = _imageList;
                this.indexRoomImageViewer = index;
            }
            else
                this.visibleRoomImageViewer = isShow
        },
        gotoFactsheet:async function(){
            if (this.selectedHotel.hotelfacesheet != undefined){
                window.open(`${this.selectedHotel.hotelfacesheet.id}`,"_blank");

                // const resData = await api.showUrlPdf(this.selectedHotel.hotelfacesheet.id);

                // var file = new Blob([resData], {type: 'application/pdf'});
                //     var fileURL = URL.createObjectURL(file);
                //     window.open(fileURL);
            }
            // https://drive.google.com/file/d/1GvLzWsAifsC18Je78TgJpNf8Cb40_V23/view?usp=sharing
        },
        gotoResortMap(isShow){
            this.visibleMapViewer = isShow;
        },

        
        gotoRoomAmenities(_index){
            const _curShow = this.visibleRoomAmenities[_index];
            this.visibleRoomAmenities.splice(_index, 1, !_curShow);
        },

        gotoViewRates:async function(){

        },


        showRoomMore(_index){
            const _curShow = this.isShowRoomMore[_index];
            this.isShowRoomMore.splice(_index, 1, !_curShow);
        },
        showRoomTextMore(_text){
            this.visibleDetailItem = true;
            this.detailItemText = _text;

            // const _curShow = this.isShowRoomTextMore[_index];
            // this.isShowRoomTextMore.splice(_index, 1, !_curShow);
        },
        showFloorPlan(_index, isMobile = false){
            if (!isMobile){
                const _curShow = this.visibleFloorPlan[_index];
                this.visibleFloorPlan.splice(_index, 1, !_curShow);
            }
            else{
                const _curShow = this.visibleFloorPlanMobile[_index];
                this.visibleFloorPlanMobile.splice(_index, 1, !_curShow);
            }
        },
        
    // eslint-disable-next-line no-unused-vars
        showRates(_index){
            // if (!common.getLoginState(this.$store)){
            //     this.$message.error('Rates are restricted to VUE members only. Please register or sign-in.');
            //     bus.$emit('showLogin', true);
            //     return;
            // }
            this.$message.error('Please select checkin and checkout date, adults count correctly.');

            // const _curShow = this.isShowRates[_index];
            // this.isShowRates.splice(_index, 1, !_curShow);
        },
        showInclusion(_index){
            if (this.selectedHotel.promotion[_index] != undefined && !this.selectedHotel.promotion[_index])
                this.inclusionText = this.selectedHotel.promotion[_index].description;
            else
                this.inclusionText = "-\tComplimentary upgrade from Earth beach villa to Earth villa with pool or Overwater Wind villa to Overwater villa with pool\n-\tReturn (roundtrip) transfers – 45 minutes via speedboat for two person\n-\tDaily champagne breakfast with a wide variety of cuisine; assorted selection of cheeses, smoked meats, cold cuts; exotic fruits.\n-\tDaily lunch with selection of light meals and snacks.\n-\tDaily dinner dine-around fine dining experiences which includes international cuisine, theme nights, authentic south Asian specialty cuisine from India and Sri Lanka; Specialty Chinese cuisine.\n-\tAll day delectable choice of snacks and pizza\n-\tPremium selection of select champagne, whiskeys, international beers and a wide selection of branded spirits.\nExotic wine collection of over 80 labels of Old and New World.\n-\tUnlimited international popular cocktails; selection of exotic teas and coffee varieties\n-\tOne-time fine dining at Underwater specialty restaurant. M6M. (based on 4 nights minimum stay)\n-\tIn-villa mini bar replenished twice daily\n-\tFull access to fitness and recreation centre services. (1) adventure excursion per stay; (1) sunset fishing excursion per day; Unlimited snorkeling excursions\n-(1) 60-minute massage or dive (staying min. 4 nights). Staying for min. of 6 nights will receive (2) massages or dive, per person\n-\tTax & Services, Green tax included in rate";
            this.visibleInclusion = true;
            this.visibleInclusionTitle = "INCLUSIONS";
        },
        showTermsCondition(_index){
            if (this.selectedHotel.promotion[_index] != undefined && !this.selectedHotel.promotion[_index])
                this.inclusionText = this.selectedHotel.promotion[_index].termsconditions;
            else
                this.inclusionText = "Credit card & a valid form of ID will be required at time of booking, to guarantee your booking. Payments will be collected based on the terms below. \n\nNow - 31st October 2021:\nPayment Terms: \n100% due 10 days prior to check-in\nCancellation Policy:\nLess than 7 days to arrival, 100% penalty\n\nFestive period (20th Dec - 10th Jan, 2022) \nPayment Terms: \n100% due 40 days prior to check-in\nCancellation Policy:\nLess than 30 days to arrival, 100% penalty\n\nAll remaining dates (excluding festive period)\nPayment Terms: \n100% due 20 days prior to check-in\nCancellation Policy:\nLess than 14 days to arrival, 100% penalty";
            this.visibleInclusion = true;
            this.visibleInclusionTitle = "TERMS & CONDITIONS";
        },
        
    // eslint-disable-next-line no-unused-vars
        gotoCheckout(roomindex,rateindex){
            
            this.$message.error('Please select checkin and checkout date, adults count correctly.');
            window.scrollTo(0,0);
            return;


        },
        
        gotoHotelSearchClick(isShow){
            if (this.searchStartDate == null || this.searchEndDate == null){
                this.$message.error('Please select date range.');
                return;
            }
            
            if (this.searchRoomInfo.length <= 0){
                this.visibleSearchCountPickerLast = isShow;
                if (isShow)
                    this.searchRoomInfoID = this.searchRoomInfo.length;
            return;
            }
            this.gotoHotelSearch(); 
        },
            
        getCountInfosSearch(_infos){
            this.searchRoomInfo = _infos
            this.visibleSearchCountPickerLast = false;
            this.gotoHotelSearch();
        },

        gotoHotelSearch(){
            if (this.searchStartDate == null || this.searchEndDate == null){
                this.$message.error('Please select date range.');
                return;
            }
            if (this.searchRoomInfo.length <= 0){
                this.$message.error('Please enter search information.');
                return;
            }
            if (this.searchRoomInfo.filter(obj => obj.searchAdultCount == 0).length > 0){
                this.$message.error('Please enter search information.');
                return;
            }

            // if (!common.getLoginState(this.$store)){
            //     this.$message.error('This site is exclusive to VUE members only. Please sign-up or log-in.');
            //     bus.$emit('showLogin', true);
            //     return;
            // }

            let strSearchInfo = JSON.stringify(this.searchRoomInfo);
            let strSelHotelInfo = JSON.stringify(this.selHotelInfo);
            
            this.$store.commit('setBookListInfo', {
                userid: '',
                data:[],
                searchStartDate:'',
                searchEndDate:''
            });
            if (this.selHotelInfo.includes(this.hotelId)){
                let routeData = this.$router.resolve({ path: '/resorts2/', query: {
                    searchStartDate:this.searchStartDate,
                    searchEndDate:this.searchEndDate,
                    searchRoomInfo:strSearchInfo,
                    selHotelInfo:strSelHotelInfo,
                    selectedHotelId:this.hotelId
                }});
                this.$router.push(routeData.href);
                // window.open(routeData.href);
            }
            else{
                let routeData = this.$router.resolve({ path: '/resorts2/', query: {
                    searchStartDate:this.searchStartDate,
                    searchEndDate:this.searchEndDate,
                    searchRoomInfo:strSearchInfo,
                    selHotelInfo:strSelHotelInfo,
                }});
                this.$router.push(routeData.href);
                // window.open(routeData.href);
            }

            // , query: {hotelid:this.hotelId,
            // roomid:this.rooms[roomindex]._id,
            // rateindex:rateindex}})
        },
        onChangeSearchDate(date, dateString) {
            this.searchStartDate = dateString[0];
            this.searchEndDate = dateString[1];
        },
        showSearchCountPicker(isShow){
            this.visibleSearchCountPicker = isShow;
            if (isShow)
                this.searchRoomInfoID = this.searchRoomInfo.length;
        },
        getCountInfo(_info){
            this.searchRoomInfoID = _info.searchRoomInfoID;
            this.searchRoomInfo.splice(
                this.searchRoomInfoID, 1,
                {
                    searchAdultCount : _info.searchAdultCount,
                    searchChildCount : _info.searchChildCount,
                    searchChildAge : _info.searchChildAge
                }
            )
            this.visibleSearchCountPicker = false;
        },
        
        getCountInfos(_infos){
            this.searchRoomInfo = _infos
            this.visibleSearchCountPicker = false;
        },
        handleCountCancel(){
            this.visibleSearchCountPicker = false;
        },
        removeSearchRoomInfo(_index){
            this.searchRoomInfo.splice(_index, 1);
        },
        editSearchRoomInfo(_index){
            this.visibleSearchCountPicker = true;
            this.searchRoomInfoID = _index;
            this.visibleRoomList = false;
            this.visibleRoomListMobile = false;
        },

        
        afterPageChange(page, _index) {
            this.sliderStartIndex[_index] = page;
        },
        prevSlide(_index, _length){
            var aIndex = this.sliderStartIndex[_index] - 1;
            if (aIndex < 0)
                aIndex = _length - 1;
            this.sliderStartIndex.splice(_index, 1, aIndex);
        },
        nextSlide(_index, _length){
            var aIndex = this.sliderStartIndex[_index] + 1;
            if (aIndex >= _length)
                aIndex = 0;
            this.sliderStartIndex.splice(_index, 1, aIndex);
        },
    },
};
</script>
<style scoped>
    .sliderImageDetail{
        height: 100%;
        /* 60vh; */
        width: 100%;
        background-color: white;
        object-fit: cover;
        border-radius: 12px;
        overflow: hidden;
    }
    @media only screen and (max-width: 767px) {
        .sliderImageDetail {
            max-height: 40vh;
            height: 60vh;
            border-radius: 12px 12px 0px 0px;
        }
    }

    .imageHotelDetail{
        display: block;
        object-fit: cover;
        width:100px;
        height:100px;
        margin:auto
    }
        
    .slideImageHomePreviewer{
        display: block;
        max-width: 1080px;
        object-fit: cover;
    }
    
    
</style>
<!-- <style>

    .column{
        /* width: 100%; */
        /* display: block; */
    }
</style> -->